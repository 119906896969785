import React from "react";
import GlobalLayout from "../layouts/globalLayout";
import ContactUsForm from "../components/ContactUsForm/contactUsForm";
import { Grid } from "@material-ui/core";
import * as styles from "./contact.module.css";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";

const imagesQuery = graphql`{
  tableWithGlasses: file(relativePath: {eq: "contactUs/tableWithGlass.jpg"}) {
    childImageSharp {
      gatsbyImageData(transformOptions: {cropFocus: CENTER})
    }
  },
}`;


export default function Contact() {
  const images = useStaticQuery(imagesQuery);
  const url = useLocation();

  const wishlistContact = url.search ? url.search.includes("wishlist") : false;

  return (
    <GlobalLayout>
      <Grid container>
        <Grid item xs={12} md={6} className={styles.headerTitleContainer}>
          <div className={styles.makeSomeMagic}>
            let’s make
          </div>
          <div className={styles.makeSomeMagic}>
            some magic
          </div>
          <div className={styles.makeSomeMagicSubDesc}>
            WE CAN'T WAIT TO HEAR FROM YOU!
          </div>
          <div className={styles.makeSomeMagicSubDesc}>
            KINDLY FILL OUT THE FORM BELOW.
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <GatsbyImage image={images.tableWithGlasses.childImageSharp.gatsbyImageData} />
        </Grid>
      </Grid>
      <div>
        {wishlistContact && <div className={styles.wishlistHeaderText}>CONTACT US TO MAKE YOUR WISHES COME TRUE</div>}
      </div>
      <ContactUsForm includeWishList={wishlistContact} />
      <div className={styles.contactInfoContainer}>
          Or drop us a note by emailing us at: <a className={styles.contactLink} href="mailto:info@loveshack-vintage.com">info@loveshack-vintage.com</a>
      </div>
    </GlobalLayout>
  );
}
